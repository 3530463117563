<template>
  <el-dialog :title="title" :visible.sync="visible" width="40%" :before-close="beforeClose">
    <slot></slot>
    <span slot="footer" class="dialog-footer">
      <el-button v-if="isShowCancel" @click="cancel">取消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: "DialogInfo",
  data() {
    return {
      visible: this.dialogVisible
    };
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    isShowCancel: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    dialogVisible(newVal) {
      this.visible = newVal;
    }
  },
  methods: {
    confirm() {
      this.$emit("confirmDialog");
    },
    cancel() {
      this.$emit("cancelDialog");
    },
    beforeClose(done) {
      done();
      this.$emit("closeDialog");
    }
  }
};
</script>
<style scoped lang="scss">
::v-deep .el-dialog__title {
  font-size: 15px;
  font-weight: bold;
}

::v-deep .el-dialog__header {
  background-color: #f5f5f5;
}

::v-deep .el-dialog__footer {
  margin-top: 20px;
  border-top: 1px solid #e7e7e7;
  padding-top: 20px;
}
</style>