// 身份证号正则
export function isIDCard(idCode) {
  let reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  return reg.test(idCode);
}

//日期正则  2017-02-11
export function formatDate(time) {
  let reg = /^(?:(?!0000)[0-9]{4}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-8])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)|(?:[0-9]{2}(?:0[48]|[2468][048]|[13579][26])|(?:0[48]|[2468][048]|[13579][26])00)-02-29)$/;
  return reg.test(time)
}

// 校验文件后缀
export function fileSuffix(str) {
  let strRegex = "(.jpg|.gif|.txt)";
  let reg = new RegExp(strRegex);
  return reg.test(str)
}

// 用户名正则，4到16位（字母，数字，下划线，减号）
export function isUserName(str) {
  let reg = /^[a-zA-Z0-9_-]{4,16}$/;
  return reg.test(str)
}

// 用户名为验证汉字或英文
export function isHanzi(str) {
  let reg = /^[\u0391-\uFFE5A-Za-z]+$/;
  return reg.test(str)
}

// 用户名为验证汉字
export function isNickname(str) {
  let reg = /^[\u4e00-\u9fa5]+$/;
  return reg.test(str)
}

// 密码强度正则  不能使用特殊字符，长度在8-10之间
export function isPassword1(str) {
  let reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,10}$/;
  return reg.test(str)
}

// 密码强度正则，最少6位，包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符
export function isPassword2(str) {
  let reg = /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/;
  return reg.test(str)
}

// 精确到1位小数
export function isNumber1(number) {
  let reg = /^[1-9][0-9]*$|^[1-9][0-9]*\.[0-9]$|^0\.[0-9]$/;
  return reg.test(number)
}

// 精确到2位小数
export function isNumber2(number) {
  let reg = /^[0-9]+(.[0-9]{2})?$/;
  return reg.test(number)
}

// 浮点数
export function isNumber3(number) {
  let reg = /^(?:[-+])?(?:[0-9]+)?(?:\.[0-9]*)?(?:[eE][\+\-]?(?:[0-9]+))?$/;
  return reg.test(number)
}

// 浮点数正则 +334.4443434343e3
export function isNumber4(number) {
  let reg = /[-+]?(?:\b[0-9]+(?:\.[0-9]*)?|\.[0-9]+\b)(?:[eE][-+]?[0-9]+\b)?/;
  return reg.test(number)
}

// 整数正则 -42
export function isNumber5(number) {
  let reg = /^-?\d+$/;
  return reg.test(number)
}

// 正数正则 42.2
export function isNumber6(number) {
  let reg = /^\d*\.?\d+$/;
  return reg.test(number)
}

// 负数正则
export function isNumber7(number) {
  let reg = /^-\d*\.?\d+$/;
  return reg.test(number)
}

// 数字正则 -42.2
export function isNumber8(number) {
  let reg = /^-?\d*\.?\d+$/;
  return reg.test(number)
}

// Email正则
export function isEmail(email) {
  let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  return reg.test(email)
}

// 1.邮箱以a-z、A-Z、0-9开头，最小长度为1.
// 2.如果左侧部分包含-、_、.则这些特殊符号的前面必须包一位数字或字母。
// 3.@符号是必填项
// 4.右则部分可分为两部分，第一部分为邮件提供商域名地址，第二部分为域名后缀，现已知的最短为2位。
//   最长的为6为。
// 5.邮件提供商域可以包含特殊字符-、_、.
export function isMailbox(mail) {
  let reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
  return reg.test(mail)
}

//* 国际码 如：中国(+86) 手机号/电话号正则
export function isPhone(mail) {
  let reg = /^((\+?[0-9]{1,4})|(\(\+86\)))?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])\d{8}$/;
  return reg.test(mail)
}

// URL正则
export function isUrl () {
  let reg = /^((https?|ftp|file):\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
  //获取url中域名、协议正则 'http://xxx.xx/xxx','https://xxx.xx/xxx','//xxx.xx/xxx'
  // /^(http(?:|s)\:)*\/\/([^\/]+)/.test("http://www.baidu.com");

  // /^((http|https):\/\/(\w+:{0,1}\w*@)?(\S+)|)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/.test('https://www.baidu.com/s?wd=@#%$^&%$#');
  return reg.test(mail)
}

// IP地址正则
export function isIP(ip) {
  let reg = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return reg.test(ip)
}

// 车牌号正则
export function isPlateNumber(plateNumber) {
  let reg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/;
  return reg.test(plateNumber)
}


// 给一个连字符串例如：get-element-by-id 转化成驼峰形式。
// 匹配到到是-e -b -i 形式截取后一个字符转成大写
export function camelback (str) {
  let reg = /-\w/g; // 匹配横杆以及之后的一个字符，全局匹配
  str.replace(reg, function($0) {
    return $0.slice(1).toUpperCase();
  })
}

// 10.000.000  分割数字每三个以一个逗号划分 10000000000
export function moneyType(str) {
  let reg = /(?=(\B)(\d{3})+$)/g;
  return str.replace(reg, ".");
}
