// 引入工具函数
import {
  http,
  httpimg
} from '../../api/index'

//查询事件列表
export const getEventListApi = params => http.get('/ohealth/api/v1/epidemicControl/event/getEventList', params)
// 保存流调详情
export const saveSurveyInfoApi = params => http.post('/ohealth/api/v1/epidemicControl/epidemiologicalsurvey/saveSurveyInfo', params)
//编辑流调详情
export const updateSurveyInfoApi = params => http.put('/ohealth/api/v1/epidemicControl/epidemiologicalsurvey/updateSurveyInfo', params)
//流调列表
export const getEpidemiologicalSurveyListApi = params => http.post('/ohealth/api/v1/epidemicControl/epidemiologicalsurvey/epidemiologicalSurveyList', params)
//登记列表
export const listApi = params => http.get('/ohealth/api/v1/epidemicControl/epidemiologicalsurvey/list', params)
//查询流调详情
export const getSurveyInfoApi = params => http.get('/ohealth/api/v1/epidemicControl/epidemiologicalsurvey/getSurveyInfo', params)
//初始化转运任务
export const initTransportApi = params => http.post('/ohealth/api/v1/epidemicControl/epidemiologicalsurvey/initTransport', params)
//发函
export const sendLetterApi = params => httpimg.post('/ohealth/api/v1/epidemicControl/epidemiologicalsurvey/sendLetter', params)
//查询详情人员信息
export const getSurveyPersonInfoApi = params => http.get('/ohealth/api/v1/epidemicControl/epidemiologicalsurvey/getSurveyPersonInfo', params)
//查询任务详情
export const getTransportInfoApi = params => http.get('/ohealth/api/v1/epidemicControl/transporttask/getTransportInfo', params)
//获取待转运人员和待发函人员数量
export const getTransportAndSendWaitPersonNumApi = params => http.get('/ohealth/api/v1/epidemicControl/epidemiologicalsurvey/getTransportAndSendWaitPersonNum', params)
//根据登记ID查询登记信息
export const getRegInfoByRegIdApi = params => http.post('/ohealth/api/v1/isolation/reg/getRegInfoByRegId', params)
//校验是否已经流调登记
export const checkIsRegApi = params => http.post('/ohealth/api/v1/epidemicControl/epidemiologicalsurvey/checkIsReg', params)
//查询待流调人员列表
export const getWaitSurveyList = params => http.get('/ohealth/api/v1/epidemicControl/pretaskuser/getWaitSurveyList', params)
//查询流调员列表
export const getSurveyUserList = params => http.get('/ohealth/api/v1/epidemicControl/surveygroupinfo/getSurveyUserList', params)
//查询已入住人员未关联流调列表
export const getRegLiveUnSurveyUserList = params => http.get('/ohealth/api/v1/isolation/reg/getRegLiveUnSurveyUserList', params)

//新增前置任务
export const savePreTaskApi = params => http.post('/ohealth/api/v1/epidemicControl/pretaskinfo/savePreTask', params)
//修改前置任务
export const updatePreTaskApi = params => http.put('/ohealth/api/v1/epidemicControl/pretaskinfo/updatePreTask', params)
//分页查询前置任务列表
export const getPreTaskPageApi = params => http.get('/ohealth/api/v1/epidemicControl/pretaskinfo/getPreTaskPage', params)
//涉事场所列表
export const getPlaceListApi = params => http.get('/ohealth/api/v1/epidemicControl/placeinvolved/getPlaceList', params)
//查询待流调人员列表
export const getSurveyGroupUserListApi = params => http.get('/ohealth/api/v1/epidemicControl/surveygroupinfo/getSurveyGroupUserList', params)
//查询前置任务详情
export const getPreTaskInfoByIdApi = params => http.get('/ohealth/api/v1/epidemicControl/pretaskinfo/getPreTaskInfoById', params)
//取消前置任务
export const cancelPreTaskApi = params => http.put('/ohealth/api/v1/epidemicControl/pretaskinfo/cancelPreTask', params)
//上传解析前置任务人员列表
export const uploadPreTaskExcelApi = params => http.post('/ohealth/api/v1/epidemicControl/pretaskuser/uploadPreTaskExcel', params)
//校验前置任务中重复人员
export const checkPreTaskApi = params => http.post('/ohealth/api/v1/epidemicControl/pretaskinfo/checkPreTask', params)
//修改待流调人员流调员
export const updatePreTaskUserSurveyUser = params => http.put('/ohealth/api/v1/epidemicControl/pretaskuser/updatePreTaskUserSurveyUser', params)
//查询涉事场所列表
export const getPlaceList = params => http.get('/ohealth/api/v1/epidemicControl/placeinvolved/getPlaceList', params)
//转运待处理任务确认异常
export const confirmExceptionApi = params => http.put('/ohealth/api/v1/epidemicControl/transporttask/confirmException', params)
//根据入住登记ID查询入住登记人员信息
export const getRegById = params => http.get('/ohealth/api/v1/epidemicControl/epidemiologicalsurvey/getRegById', params)
//流调关联人员列表查询
export const relationList = params => http.get('/ohealth/api/v1/epidemicControl/epidemiologicalsurvey/relationList', params)
//疾控任务管理导入模板下载
export const exportTaskTemplate = params => httpimg.post('/ohealth/api/v1/epidemicControl/pretaskinfo/exportTaskTemplate', params)
//疾控批量录入导入模板下载
export const exportTemplateKeyIn = params => httpimg.post('/ohealth/api/v1/epidemicControl/epidemiologicalsurvey/exportTemplate', params)
// 疾控批量录入查重
export const batchCheckIsReg = params => http.post('/ohealth/api/v1/epidemicControl/epidemiologicalsurvey/batchCheckIsReg', params)

// 疾控批量录入保存
export const saveSurveyInfoList = params => http.post('/ohealth/api/v1/epidemicControl/epidemiologicalsurvey/saveSurveyInfoList', params)

// 导出
export const exportEpidemiologicalSurveyList = params => httpimg.post('/ohealth/api/v1/epidemicControl/epidemiologicalsurvey/exportEpidemiologicalSurveyList', params)
//疫情列表查询
export const checkList = params => http.post('/ohealth/api/v1/epidemicControl/epidemiolog-check/checkList', params)
// 疫情导出
export const exportList = params => httpimg.post('/ohealth/api/v1/epidemicControl/epidemiolog-check/exportList', params)
// 流调推送居家隔离数据
export const initHomeReg = params => http.post('/ohealth/api/v1/epidemicControl/epidemiologicalsurvey/initHomeReg', params)

// 查询阳性分页列表
export const PositiveCasesList = params => http.post('/ohealth/api/v1/epidemicControl/epidemiologicalsurveypositive/getEpidemiologicalSurveyPositivePage', params)

// 阳性分页列表-导出
export const exportEpidemiologicalSurveyPositiveList = params => httpimg.post('/ohealth/api/v1/epidemicControl/epidemiologicalsurveypositive/exportEpidemiologicalSurveyPositiveList', params)

// 阳性新增
export const saveSurveyPositiveInfo = params => http.post('/ohealth/api/v1/epidemicControl/epidemiologicalsurveypositive/saveSurveyInfo', params)

// 根据id查询阳性人员信息
export const getPositiveInfo = params => http.get(`/ohealth/api/v1/epidemicControl/epidemiologicalsurveypositive/info/${params}` )

// 阳性编辑保存
export const updateSurveyPositive = params => http.put('/ohealth/api/v1/epidemicControl/epidemiologicalsurveypositive/updateSurveyInfo', params)

// 阳性新增查重
export const checkPositiveIsReg = params => http.post('/ohealth/api/v1/epidemicControl/epidemiologicalsurveypositive/checkIsReg', params)

// 查询近10天核酸信息
export const queryCovidListByUser = params => http.post('/ohealth/api/v1/epidemicControl/epidemiologicalsurveypositive/queryCovidListByUser', params)

// 删除
export const deleteid = params => http.delete('/ohealth/api/v1/epidemicControl/epidemiologicalsurveypositive/delete/' + params.id, params)